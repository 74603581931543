<template>
  <Section>
    <Container>
      <Checkbox
        id="selectAll"
        :modelValue="allItemsSelected"
        @update:modelValue="checkAllItems"
        :items="[{
                label: 'Sélectionner tout',
                value: true,
              }]"
      />
      <template v-for="(repetition, index) in repetitions" :key="index">
        <Checkbox
          :id="index"
          :modelValue="form.repetitions.findIndex((r) => r.repetition === repetition) !== -1"
          @update:modelValue="selectRepetitions(repetition)"
          :items="[{
            label: `Répétition ${repetition}`,
            value: true,
          }]"
        />
      </template>
    </Container>
  </Section>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Checkbox from '@/components/form/Checkbox.vue'

export default {
  name: 'RepetitionsAddSubview',
  components: {
    Checkbox,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
    })

    // init du form
    const { errors, isSubmitting } = this.formService.initFrom(validationSchema)

    // gestion du onSubmit
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      onSubmit,
      errors,
      isSubmitting,
      method: 'post',
      endpoint: null,
      essai: {},
      repetitions: [],
      form: {
        repetitions: [],
      },
      allItemsSelected: null,
    }
  },
  emits: ['set-step', 'set-data', 'set-route'],
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
    modelValue() {
      if (this.modelValue) {
        this.onSubmit()
      }
      this.$emit('update:modelValue', false)
    },
  },
  mounted() {
    if (!this.$route.name.includes('Edit')) {
      this.previousRouteName = 'essaiFixedReportModalitiesAdd'
    }

    this.$emit('set-route', {
      previousRouteName: this.previousRouteName,
      routeParams: {
        id: this.$route.params.id,
        rid: this.$route.params.rid,
      },
    })

    this.$emit('set-step', 2)

    this.getRepetitions()
  },
  methods: {
    /**
     * Get repetitions from protocol
     */
    getRepetitions() {
      this.emitter.emit('open-loader')
      this.fetchService.get(`essai/${this.$route.params.id}`)
        .then((repetitions) => {
          this.repetitions = repetitions.data.plan.nb_repetitions
          this.getSelectedRepetitions()
          this.emitter.emit('close-loader')
        })
    },
    /**
     * Get already selected repetitions
     */
    getSelectedRepetitions() {
      this.fetchService.get(`essai/${this.$route.params.id}/rapport/${this.$route.params.rid}/repetition`, { limit: 0 })
        .then((response) => {
          console.log(response.data)
          response.data.forEach((repetition) => {
            this.selectRepetitions(repetition.repetition)
          })
        })
    },

    selectRepetitions(repetition) {
      const index = this.form.repetitions.findIndex((r) => r.repetition === repetition)
      if (index > -1) {
        this.form.repetitions.splice(index, 1)
      } else {
        this.form.repetitions.push({
          repetition,
        })
      }

      if (this.isAllItemChecked()) {
        this.allItemsSelected = true
      } else {
        this.allItemsSelected = false
      }
    },

    checkAllItems() {
      if (!this.isAllItemChecked()) {
        this.form.repetitions = []
        const array = []
        let count = 0
        for (let i = 0; i < this.repetitions; i += 1) {
          count = i + 1
          array.push(count)
        }
        array.forEach((a) => {
          this.selectRepetitions(a)
        })
      } else {
        this.form.repetitions = []
      }
    },

    isAllItemChecked() {
      let isAllIn = true
      const repetitions = []
      this.form.repetitions.forEach((m) => {
        repetitions.push(m.repetition)
      })
      console.log('repet', this.repetitions)

      const array = []
      let count = 0
      for (let i = 0; i < this.repetitions; i += 1) {
        count = i + 1
        array.push(count)
      }
      array.forEach((a) => {
        if (!repetitions.includes(a)) {
          isAllIn = false
        }
      })

      return isAllIn
    },

    handleValues(values) {
      this.emitter.emit('open-loader')
      console.log('Values', values)
      this.fetchService
        .put(`essai/${this.$route.params.id}/rapport/${this.$route.params.rid}/repetition`, {
          repetitions: this.form.repetitions,
        })
        .then(() => {
          const params = {
            id: this.$route.params.id,
            rid: this.$route.params.rid,
          }
          this.$router.push({
            name: 'essaiFixedReportVariablesAdd',
            params,
          })
          this.emitter.emit('close-loader')
        },
        (error) => {
          this.emitter.emit('close-loader')
          this.formService.handleApiError(error)
        })
    },
  },
}
</script>
